import css from 'styled-jsx/css';

export const styles = css`
    footer .social-medias a path {
        fill: #999;
    }

    .svg-inline--fa {
        height: 22px;
    }

    footer .footer-container {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;

        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;

        flex-direction: column;
    }

    footer .address a {
        color: #fff;
        font-weight: 600;
        border-bottom: 1px solid #fff;
    }

    footer .social-medias .svg-inline--fa2 {
        height: 22px;
    }

    footer div svg {
        height: 25px;
        width: 25px;
    }

    footer .social-medias path {
        fill: #999;
    }
`;
