export default function GotoTop() {
    const goToTopClick = e => {
        e.preventDefault();
        window.scrollTo(0, 0);
    };

    return (
        <div className={`gotop hover:text-gray-50 fixed bottom-5 left-8 flex h-9 w-9 items-center justify-center rounded-sm print:hidden`}>
            <a className="text-black" aria-label="Go to Top" href="#" onClick={e => goToTopClick(e)}>
                <svg xmlns="http://www.w3.org/2000/svg" className="mx-[12px] h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7" />
                </svg>
            </a>
            <style>{`
                .gotop {
                    background: hsla(0, 0%, 100%, 0.2);
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
                }
            `}</style>
        </div>
    );
}
