import dynamic from 'next/dynamic';
import { handleViewport as ViewPort } from 'react-in-viewport';

import { styles } from './Footer-styles';
import { useAppTheme } from '../GlobalAppTheme';
import React, { useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
// import SocialMediaIcons from './SocialMediaIcons';
import GotoTop from './GotoTop';

const SocialMediaIcons = dynamic(() => import('./SocialMediaIcons'));
const SocialMediaIconsViewportBlock = ViewPort(SocialMediaIcons);

const Footer = () => {
    const theme = useAppTheme();
    const footer = theme?.footer;
    const additionalLinks = footer?.additionalLinks;
    const handleScroll = () => {
        // The "Go to top" arrow shows when vertical scroll offset is greater than 800
        let scroll = window.scrollY;
        let goto = document.querySelector('.gotop');
        let classes = goto?.classList;
        if (scroll > 800) {
            classes?.remove('hidden');
            classes?.add('block');
        } else {
            classes?.remove('block');
            classes?.add('hidden');
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <footer className="flex w-full flex-col justify-center bg-charcoal py-20 text-white print:hidden">
            <div className={styles.footerContainer}>
                <div className="footer-logo mx-10 mb-5 flex justify-center">
                    {theme?.siteLogoAlt1?.sourceUrl && (
                        <Link aria-label="Go to homepage" href="/">
                            <Image src={theme?.siteLogoAlt1?.sourceUrl} width={130} height={180} alt={theme?.siteLogoAlt1?.altText} />
                        </Link>
                    )}
                </div>
                <div className="address text-center">
                    <div>
                        Jews for Jesus
                        <br />
                        {footer?.address}
                        <br />
                        {footer?.cityStateZip}
                        <br />
                        <br />
                        Phone: {footer?.phoneNumber}
                        <br />
                        Email: <a href="mailto:jfj@jewsforjesus.org">{footer?.emailAddress}</a>
                        <br />
                        <br />
                        <Link href="/about" className="border-b text-white" passHref>
                            About
                        </Link>{' '}
                        |{' '}
                        <Link href="/learning" className="border-b text-white" passHref>
                            Learn
                        </Link>{' '}
                        |{' '}
                        <Link href="https://store.jewsforjesus.org" target="_blank" className="border-b text-white" passHref>
                            Store
                        </Link>{' '}
                        |{' '}
                        <Link href="/contact" className="border-b text-white" passHref>
                            Contact
                        </Link>
                        <br />
                        <br />
                        {additionalLinks?.map((link, index) => {
                            const additionalLink = link.link;
                            const endOfLineIndexes = [2, 5, 8];
                            const pipe = !endOfLineIndexes.includes(index) && index !== additionalLinks.length - 1 ? '|' : <br />;
                            return (
                                <React.Fragment key={index}>
                                    <a href={additionalLink?.url}>{additionalLink?.title}</a> {pipe}{' '}
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            </div>
            <SocialMediaIconsViewportBlock footer={footer} />
            <GotoTop />
        </footer>
    );
};

export default Footer;
